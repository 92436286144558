import React, { useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { customSTLLoader } from './CustomSTLLoader';
import { API_BASE_URL } from '../config';
import { AuthContext } from '../context/AuthContext';
import "./Scene.css"

const Scene = () => {
  const { stlPath } = useParams();
  const mountRef = useRef(null);
  const sceneRef = useRef();
  const meshRef = useRef();
  const cameraRef = useRef();
  const rendererRef = useRef();
  const controlsRef = useRef();

  const { token } = useContext(AuthContext);

  useEffect(() => {
    const currentMount = mountRef.current

    //Scene
    const scene = new THREE.Scene()
    sceneRef.current = scene;
    scene.background = new THREE.Color(0x0f0f0f);

    // Camera
    const camera = new THREE.PerspectiveCamera(
        75,
        currentMount.clientWidth / currentMount.clientHeight,
        0.1,
        1000
    )
    camera.position.z = 15
    scene.add(camera)
    cameraRef.current = camera;

    // Renderer
    const renderer = new THREE.WebGLRenderer({
        antialias: true,  // Enable antialiasing
        alpha: true,      // Enable alpha channel for better blending
    });
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setSize(currentMount.clientWidth, 
                     currentMount.clientHeight);
                     
    currentMount.appendChild(renderer.domElement);
                     
    const resize = () => {
        renderer.setSize(currentMount.clientWidth, 
                         currentMount.clientHeight);
        
    }
    window.addEventListener("resize", resize)

    rendererRef.current = renderer;

    // Add light to the scene 
    const light = new THREE.DirectionalLight(0xffffff, 1);
    // Set it behing the camera (z = 6) (check coordinate system)
    light.position.set(0, 0, 6);
    scene.add(light);
    // Add second source of light to the scene 
    const light1 = new THREE.DirectionalLight(0xffffff, 0.6);
    light1.position.set(2, 0, -6);
    scene.add(light1);
    // And a third
    const light2 = new THREE.DirectionalLight(0xffffff, 0.6);
    light2.position.set(-2, 0, -6);
    scene.add(light2);    
        
    // Add controls so that we can maipulate the object
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = false

    controlsRef.current = controls
    
    // We need an animate function along with the controls constant
    function animate() {            
        // Render the scene 
        controls.update();
        renderer.render(scene, camera)
        requestAnimationFrame(animate)
    }
    
    animate();

    //Clean up scene
    return () => {
        currentMount.removeChild(renderer.domElement)
    }
  }, []);

  useEffect(() => {
    if (!stlPath) {
      console.error("No stlPath provided");
      return;
    }

    console.log("Attempting to load STL for stlPath:", stlPath);

    customSTLLoader(`${API_BASE_URL}/api/stl/${encodeURIComponent(stlPath)}`, {
      Authorization: `Bearer ${token}`,
    })
      .then((geometry) => {
        console.log("STL loaded successfully");
        // Remove the previous mesh from the scene if it exists
        if (meshRef.current) {
            sceneRef.current.remove(meshRef.current);
        }
    
        const mesh = new THREE.Mesh(geometry);
    
        const material = new THREE.MeshLambertMaterial({
            color: 0x990000, // set the color of the material to red to represent the blood vessel
            opacity: 1, // set the opacity to a value that makes the material slightly transparent
            reflectivity: 0,
            side: THREE.DoubleSide,
        });
        mesh.material = material;
        // Loaded objects tend to be huge. We need to scale them down
        mesh.scale.set(0.1, 0.1, 0.1);
        mesh.position.set(0, 0, 0);
        // Add a 270 degree rotation along the x axis
        mesh.rotation.x = Math.PI / 2;
        mesh.rotation.y = Math.PI;
        // Add mesh to the scene
        sceneRef.current.add(mesh);
        meshRef.current = mesh;

        // Center the camera and the new STL position
        const box = new THREE.Box3().setFromObject(mesh);
        const boxCenter = box.getCenter(new THREE.Vector3());

        // const controls = new OrbitControls(cameraRef.current, rendererRef.current.domElement);
        controlsRef.current.target.copy(boxCenter);

        cameraRef.current.position.copy(boxCenter);
        cameraRef.current.position.z += 25
        // cameraRef.current.near = boxSize / 100;
        // cameraRef.current.far = boxSize * 100;
        cameraRef.current.updateProjectionMatrix();

        controlsRef.current.update();

      })
      .catch((error) => {
        console.error('Failed to load the STL file:', error);
        // Optionally, display an error message to the user
      });
  }, [stlPath, token]);

  return (
      <div
          className='viewer-3D'
          ref={mountRef}
          style={{width:'100%', height:'100vh'}}
      ></div>
  )
}

export default Scene;