import React, { useState } from 'react';
import LogoutButton from './LogoutButton';
import './TopBarDropDownMenu.css';

const TopBarDropDownMenu = ({ onLogout }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className={`dropdown-menu${dropdownVisible ? ' show' : ''}`} style={{ marginRight: 10 }}>
      <button className="menu-button" onClick={toggleDropdown}>
        <span className="menu-bar" />
        <span className="menu-bar" />
        <span className="menu-bar" />
      </button>
      {dropdownVisible && (
        <div className="dropdown-content">
          {/* You can add more menu items here */}
          <LogoutButton onLogout={onLogout} />
        </div>
      )}
    </div>
  );
};

export default TopBarDropDownMenu;
