import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import "./LoginForm.css";
import logo from './logos/Arterial_logo_tallat.png';

const LoginForm = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const userButtonRef = useRef(null);
  const logoRef = useRef(null);

  useEffect(() => {
    if (userButtonRef.current && logoRef.current) {
      const userButtonWidth = userButtonRef.current.getBoundingClientRect().width;
      logoRef.current.style.width = `${userButtonWidth}px`;
    }
  }, [userButtonRef, logoRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/login`, {
        username,
        password,
      });

      if (response.data.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("token_timestamp", Date.now());
        onLoginSuccess(response.data.access_token);
      } else {
        alert("Invalid username or password");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
    }
  };

  return (
    <div className="LoginForm">
      <div className="login-container">
            <img ref={logoRef} src={logo} className = "arterial-logo" alt = "Logo"/>
            <br />
        <form onSubmit={handleSubmit} className="login-form">
        <input
            ref={userButtonRef}
            className="UserButton"
            type="text"
            placeholder="User"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <input
            className="UserButton"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <button className="LoginButton" type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
