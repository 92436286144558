import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TopBarDropDownMenu from './TopBarDropDownMenu';
import BackButton from './BackButton';
import logo from './logos/Arterial_logo_tallat.png';
import './TopBar.css';

const TopBar = ({ onLogout }) => {
    const [dropdownActive, setDropdownActive] = useState(false);

    const toggleDropdown = () => {
        setDropdownActive(!dropdownActive);
      };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="top-bar">
            <BackButton onClick={goBack} />
            <Link to="/">
                <img src={logo} alt="Logo" className="top-bar-logo" />
            </Link>
            <TopBarDropDownMenu onClick={toggleDropdown} onLogout={onLogout} />
        </div>
    );
};

export default TopBar;
