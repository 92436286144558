import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { API_BASE_URL } from '../config';
import "./Scene.css"

const DemoScene2 = () => {
  const mountRef = useRef(null);
  const sceneRef = useRef();
  const meshRef = useRef();
  const cameraRef = useRef();
  const rendererRef = useRef();
  const controlsRef = useRef();

  useEffect(() => {
    const currentMount = mountRef.current

    // Scene setup
    const scene = new THREE.Scene()
    sceneRef.current = scene;
    scene.background = new THREE.Color(0x0f0f0f);

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
        75,
        currentMount.clientWidth / currentMount.clientHeight,
        0.1,
        1000
    )
    camera.position.z = 15
    scene.add(camera)
    cameraRef.current = camera;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({
        antialias: true,  // Enable antialiasing
        alpha: true,      // Enable alpha channel for better blending
    });
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
    currentMount.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Lighting setup
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(0, 0, 6);
    scene.add(light);
    const light1 = new THREE.DirectionalLight(0xffffff, 0.6);
    light1.position.set(2, 0, -6);
    scene.add(light1);
    const light2 = new THREE.DirectionalLight(0xffffff, 0.6);
    light2.position.set(-2, 0, -6);
    scene.add(light2);    

    // Controls setup
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = false
    controlsRef.current = controls

    // Animation function
    function animate() {            
        controls.update();
        renderer.render(scene, camera)
        requestAnimationFrame(animate)
    }
    
    animate();

    // Load STL file
    const loader = new STLLoader();
    loader.load(
      `${API_BASE_URL}/api/demo/stl2`,
      (geometry) => {
        const material = new THREE.MeshLambertMaterial({
            color: 0x990000, // set the color of the material to red to represent the blood vessel
            opacity: 1, // set the opacity to a value that makes the material slightly transparent
            reflectivity: 0,
            side: THREE.DoubleSide,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.scale.set(0.1, 0.1, 0.1);
        mesh.position.set(0, 0, 0);
        mesh.rotation.x = Math.PI / 2;
        mesh.rotation.y = Math.PI;
        scene.add(mesh);
        meshRef.current = mesh;

        // Center the camera on the loaded mesh
        const box = new THREE.Box3().setFromObject(mesh);
        const boxCenter = box.getCenter(new THREE.Vector3());
        controls.target.copy(boxCenter);
        camera.position.copy(boxCenter);
        camera.position.z += 25;
        camera.updateProjectionMatrix();
        controls.update();
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      (error) => {
        console.error('An error occurred while loading the STL file', error);
      }
    );

    // Animation function
    function animate() {            
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    }
    
    animate();

    // Resize handler
    const resize = () => {
      renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
      camera.aspect = currentMount.clientWidth / currentMount.clientHeight;
      camera.updateProjectionMatrix();
    }
    window.addEventListener("resize", resize)

    // Cleanup
    return () => {
      window.removeEventListener("resize", resize)
      currentMount.removeChild(renderer.domElement)
    }
  }, []);

  return (
    <div
      className='viewer-3D'
      ref={mountRef}
      style={{width:'100%', height:'100vh'}}
    ></div>
  )
}

export default DemoScene2;