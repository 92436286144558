import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { API_BASE_URL } from '../config';
import './PatientList.css'; 

const PatientList = () => {
    const [patients, setPatients] = useState([]);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        const fetchPatients = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/recent_patients`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setPatients(response.data);
            } catch (error) {
                console.error('Error fetching patients:', error);
            }
        };

        fetchPatients();
    }, [token]);

    return (
        <div className="patient-list">
            <h2>Recent Patients (last 48h)</h2>
            {patients.map((patient) => (
                <div key={patient.series_uid} className="patient-box">
                    <h3>Series UID: {patient.series_uid}</h3>
                    <p>Date: {new Date(patient.series_datetime).toLocaleString()}</p>
                    <Link to={`/viewer/${encodeURIComponent(patient.series_uid)}`}>
                        View 3D Model
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default PatientList;