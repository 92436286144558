import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginForm from './components/LoginForm';
import { AuthProvider } from './context/AuthContext';
import TopBar from './components/TopBar';
import DemoTopBar from './components/DemoTopBar';
import PatientList from './components/PatientList';
import Scene from './components/Scene';
import DemoScene from './components/DemoScene';
import DemoScene2 from './components/DemoScene2';
import "./App.css"

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const handleLoginSuccess = (token) => {
        setIsLoggedIn(true);
        setIsTokenExpired(false);
        localStorage.setItem("access_token", token);
        localStorage.setItem("token_timestamp", Date.now());
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_timestamp");
    };

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        const tokenTimestamp = localStorage.getItem("token_timestamp");
    
        if (token && tokenTimestamp) {
            const currentTime = Date.now();
            const tokenAge = currentTime - tokenTimestamp;
    
            if (tokenAge < 1 * 20 * 60 * 1000) {
                setIsLoggedIn(true);
            } else {
                setIsTokenExpired(true);
                alert("Session expired. Please log in again.");
                localStorage.removeItem("access_token");
                localStorage.removeItem("token_timestamp");
            }
        }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/demo" element={
                    <>
                        <DemoTopBar />
                        <div className="app-container">
                            <DemoScene />
                        </div>
                    </>
                } />
                <Route path="/demo_2" element={
                    <>
                        <DemoTopBar />
                        <div className="app-container">
                            <DemoScene2 />
                        </div>
                    </>
                } />
                <Route path="/*" element={
                    isTokenExpired || !isLoggedIn ? (
                        <LoginForm onLoginSuccess={handleLoginSuccess} />
                    ) : (
                        <AuthProvider>
                            <TopBar onLogout={handleLogout}/>
                            <div className="app-container">
                                <Routes>
                                    <Route path="/" element={<Navigate to="/patients" />} />
                                    <Route path="/patients" element={<PatientList />} />
                                    <Route path="/viewer/:stlPath" element={<Scene />} />
                                </Routes>
                            </div>
                        </AuthProvider>
                    )
                } />
            </Routes>
        </Router>
    );
}

export default App;
