import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import axios from 'axios';

export async function customSTLLoader(url, headers) {
  const loader = new STLLoader();

  try {
    console.log("Fetching STL", url, headers);
    const response = await axios.get(url, { headers, responseType: 'arraybuffer' });
    const geometry = loader.parse(response.data);
    return geometry;
  } catch (error) {
    console.error('Failed to load the STL file:', error);
    throw error;
  }
}
