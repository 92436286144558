import React from 'react';
import logo from './logos/Arterial_logo_tallat.png';
import './TopBar.css';

const DemoTopBar = () => {
    return (
        <div className="top-bar">
            <img src={logo} alt="Logo" className="top-bar-logo" />
        </div>
    );
};

export default DemoTopBar;