import React from 'react';
import "./LogoutButton.css"

const LogoutButton = ({ onLogout }) => {

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        onLogout();
    };

  return (
    <div className="logout-container">
        <button className="LogoutButton" onClick={handleLogout}>
            Close session
        </button>
    </div>
  );
};

export default LogoutButton;
