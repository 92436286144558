import React from 'react';
import './BackButton.css';

const BackButton = ({ onClick }) => {
  return (
    <button className="back-button" onClick={onClick}>
      <div className="arrow-left" />
    </button>
  );
};

export default BackButton;
